.carousal-image{
    max-height: 60vh;
}
.card-outer{
    text-align: center;
    height :420px
}
.count{
    font-size: 50px;
    font-weight: bold;
}
svg {
	height: 23rem;
	/* margin-right: 4rem; */
}
.enquiry-icon{
    width: "100%" !important;
}
#envelope {
  animation: float 2s ease-in-out infinite;
}

#star1, #star2, #star3, #star4, #star5, #star6 {
  animation: blink 1s ease-in-out infinite;
}
#star2 { animation-delay: 100ms; }
#star3 { animation-delay: 500ms; }
#star4 { animation-delay: 700ms; }
#star5 { animation-delay: 300ms; }
#star6 { animation-delay: 200ms }

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}



form {
	/* min-width: 25rem; */
	
	.title {
		font-family: 'Pacifico', cursive;
		color: #212529;
		font-size: 2.5rem;
	}
	
  .form-control {
    background-color: #f2f6f8;
		border-radius: 0.8rem;
    border: none;
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
		
    &.thick {
      height: 3.3rem;
      padding: .5rem 3.5rem;
    }
		
		&:focus {
			background-color: #f2f6f8;
			border: none;
			box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
		}
  }
	
	.message .form-control {
			padding: .5rem 1.8rem;
	}
	
  ::placeholder {
    font-family: 'Quicksand', sans-serif;
		font-weight: 600;
    font-size: 1.1rem;
    color: #838788;
    position: relative;
    left: 0;
  }
	
  input,
  textarea {
		font-family: 'Quicksand', sans-serif;
    color: #212529;
    font-size: 1.1rem;
  }
	
  .icon {
    color: #57565c;
    height: 1.3rem;
    position: absolute;
    left: 1.5rem;
    top: 1.1rem;
  }
}

.btn.btn-primary {
  font-family: 'Quicksand', sans-serif;
	font-weight: bold;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 3rem;
  border: 0;
  border-radius: 3rem;
  background-image:linear-gradient(131deg, #ffd340, #ff923c, #ff923c, #ff923c);
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;
}

.btn.btn-primary:hover:enabled {
  box-shadow: 0 0.5em 0.5em -0.4em #ff923cba;
  background-size: 100% 100%;
  transform: translateY(-0.15em);
}
.card-title{
  text-align: center;
}
.card-outer{
  border: 2px solid #ff923c !important;
  border-radius: '6px' !important
}
.card {
  border: none !important;
}
.container-fluid{
  background-color: #ff923c;
  color: #212529;
  top :0;
  height: 30px;
  z-index: 200000;
  position: sticky;

}

div , nav { 
  background-color: #212529;
  color: #f2f6f8;
}
.carousel-indicators{
  display: none !important;
}
.card-body{
  color: #f2f6f8 !important; 
}
.card{
  background-color: #212529 !important;
}
.sticky-top{
  top:30px !important
}
.card-outers{
  text-align: center;
}
.counter div{
  color: #ff923c;
}

h1,h2,h3{
  text-decoration: underline;
  text-decoration-color: #ff923c;
}
.container-fluid div{
  background-color: #ff923c;
}
.container-data{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}
.container-data a{
  color: #f2f6f8 !important;
  text-decoration: none;
}
.nav-head , .nav-link{
  color: #ff923c !important;
  font-weight: 700 !important;
}
.footer{
  border-top: solid 1px #ff923c;
}
.brand-icons{
  display: flex;
    justify-content: left;
    gap: 20px;
}
.card img{
  height: 250px;
  
}
.nav-link{
  color: #ff923c !important;
}
.toastBody , .Toastify__toast-theme--light{
  background-color:  #212529!important;
}
.Toastify__toast-body{
  height: 10px !important;
}
.Toastify__close-button > svg {
  color: #f2f6f8 !important;
}
.navbar-container , nav , .navbar-collapse , .navbar-nav{
  background-color:  #f2f6f8 !important;
}
